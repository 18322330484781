.createNewSessionButton {
  margin: 24px 0;
}

.currentDisplay {
  height: 100%;
}

.flexTest {
  display: flex;
  flex-direction: column;
}
