.preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(226, 232, 240, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 1;
}

.xl {
  width: 120px;
  height: 120px;

  .ldsRing div {
    width: 120px;
    height: 120px;
  }
}

.editIcon {
  background-color: white;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -3px;
  bottom: -4px;
  border: 0.2px solid #e9dede;
  z-index: 3;
}

.file {
  display: none;
}

.button {
  border-color: rgba(20, 41, 99, 1) !important;
  margin-bottom: var(--spacing-2x);
}

.ldsRing {
  display: inline-block;
  position: absolute;
  inset: 0;
  top: -4px;
  left: -4px;
}

.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  margin: 4px;
  border: 4px solid var(--color-teal);
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-teal) transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
