@import "/src/assets/styles/variables";
.container {
  border-radius: 36px;
  background-color: $white;
  position: absolute;
  bottom: 24px;
  height: 72px;
  width: 100%;
  width: calc(100% - 48px);
  padding: 16px 40px;
  display: flex;
  gap: 35px;
  text-align: center;
  justify-content: center;
  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 40%;
  }
}

.iconsContainer {
  display: flex;
  width: 100%;
  gap: 35px;
  justify-content: center;
  text-align: center;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  &:hover {
    cursor: pointer;
  }
}

.iconImage {
  height: 20px;
  width: 20px;
  position: relative;
  top: 8px;
}
@keyframes animate {
  from {
    background-color: $white;
  }
  to {
    background-color: $teal;
  }
}

.selected {
  background-color: $teal;
  animation: animate 0.7s;
}
