@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actionButton {
  padding-top: 36px;
  display: flex;
  justify-content: center;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: $gt-america-condensed-bold;
  align-self: center;
  text-align: center;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x);
  align-items: center;
  justify-content: center;
}
