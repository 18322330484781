@import "../../../../../assets/styles/variables";

.container {
  width: 100%;
  background-color: $white;
  border: 1px solid $light-smoke;
  border-radius: 8px;
  padding: 8px;
  text-align: left;
  position: relative;
}

.cancelIcon {
  position: absolute;
  right: 10px;
}

.marginBottom {
  margin-bottom: 16px;
}

.selected {
  border: 2px solid $teal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  line-height: 18px;
  color: $trula-black;
  background: $white;
  border-radius: 8px;
  padding: 24px 16px;
  white-space: nowrap;
}
