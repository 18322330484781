.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 270px);
  overflow: auto;
}

.messageContainer {
  width: 100%;
}

.containerLeft {
  float: left;
  width: 80%;
  margin-bottom: var(--spacing-3x);
}

.containerRight {
  float: right;
  width: 80%;
  margin-bottom: var(--spacing-3x);
}

.input {
  padding: var(--spacing-2x);
  border: none;
  border-radius: 36px;
  outline: none;
  width: calc(100% - 32px);
  cursor: pointer;
}

.enter {
  width: 40px;
  height: 40px;
  position: relative;
}

.actionBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 var(--spacing-3x);
  position: relative;
  margin-top: var(--spacing-3x);
  gap: var(--spacing-x);
}

.keyboardShrink {
  @media (max-width: 1000px) {
    max-height: 200px;
    overflow: auto;
  }
}
