@import "../../../assets/styles/variables";

.mediaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  height: 100vh;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20%;
}

.name {
  font-family: $gt-america-bold;
  font-size: 1.5rem;
  line-height: 20px;
  color: $white;
  padding: 20px;
}

.status,
.duration {
  font-size: 0.875;
  line-height: 18px;
  color: $white;
  text-align: center;
}

.avatar {
  width: 130px;
  height: 130px;
  border-radius: 100%;
}

.controlsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  width: 93%;
  bottom: 3%;
  background-color: $gray-medium;
  border-radius: 16px;
  padding: 26px 40px;
}

.leftControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 16px;
  gap: 16px;
}

.modalTitle {
  font-size: 1.5rem;
  font-family: $gt-america-condensed-bold;
}

.actions {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  height: auto;
}

.discard {
  font-family: $gt-america-condensed-bold;
}

@media screen and (orientation: landscape) {
  .controlsContainer {
    width: 30%;
    padding: 10px 15px;
    padding-bottom: 7px;
    bottom: 3%;
    left: 35%;
    gap: 5px;
  }

  .controlIcon {
    height: 32px;
    width: 32px;
  }
}