@import "mixins";

@font-face {
  font-family: "GT-America";
  src: url("/assets/fonts/gt-america-standard-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "GT-America-Bold";
  src: url("/assets/fonts/gt-america-standard-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "GT-America-Condensed-Bold";
  src: url("/assets/fonts/gt-america-condensed-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

// Fonts
$gt-america: "GT-America";
$gt-america-bold: "GT-America-Bold";
$gt-america-condensed-bold: "GT-America-Condensed-Bold";

// Colors
$black: #000000;
$white: #ffffff;
$dark-gray: #1c1b1f;
$gray-medium: #344755;

$gray-100: #f7fafc;
$gray-200: #edf2f7;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-500: #a0aec0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #2d3748;
$gray-900: #1a202c;
$gray-950: #21303b;
$gray-menu: #f2f4f8;

$trula-black: #212121;
$trula-white: #f0f2f2;
$trula-smoke: #21303b;

$salmon: #d66361;
$light-salmon: #f0c0c0;

$light-blue: #b2c2eb;
$dark-blue: #142963;

$teal: #91ebdb;
$dark-teal: #054542;

$smoke: #21303b;
$light-smoke: #bfd4db;

$brown: #7a4f47;
$light-brown: #e5c2bb;

$danger: #fe6868;

$yellow: #cc920a;

body {
  // ========= SPACING =========
  --spacing-0_25x: 2px;
  --spacing-0_5x: 4px;
  --spacing-x: 8px;
  --spacing-2x: 16px;
  --spacing-3x: 24px;
  --spacing-4x: 32px;
  --spacing-5x: 40px;
  --spacing-6x: 48px;
  --spacing-7x: 56px;
  --spacing-8x: 64px;

  // ========= COLORS =========
  // define colors
  @include defineRGBColor('black', #1a1d34);
  @include defineRGBColor('white', #ffffff);
  @include defineRGBColor('gray-900', rgba(33, 48, 59, 0.6));
  @include defineRGBColor('gray-500', #8c8e9a);
  @include defineRGBColor('gray-600', rgba(113, 128, 150, 1));
  @include defineRGBColor('gray-700', rgba(74, 85, 104, 1));
  @include defineRGBColor('gray-200', rgba(237, 242, 247, 1));
  @include defineRGBColor('gray-100', #e0e1e1);
  @include defineRGBColor('red-300', #f67a7a);
  @include defineRGBColor('red-500', rgba(234, 8, 8, 1));
  @include defineRGBColor('green-800', #054542);
  @include defineRGBColor('blue-200', rgba(191, 212, 219, 0.6));
  @include defineRGBColor('blue-800', rgba(20, 41, 99, 1));
  @include defineRGBColor('teal', #91ebdb);
  @include defineRGBColor('smoke-500', #bfd4db);

  // ============ ICONOGRAPHY ===============
  --color-icon-t1: var(--color-black);
  --color-icon-t2: var(--color-gray-500);
  --color-icon-alert: var(--color-red-300);
  --color-icon-white: var(--color-white);
  --color-icon-hover: var(--color-gray-500);

  // ========= TYPOGRAPHY =========
  --color-typography-primary: var(--color-black);
  --color-typography-secondary: var(--color-gray-600);
  --color-typography-accent: var(--color-red-300);
  --color-typography-success: var(--color-green-800);
  --color-typography-success1: rgba(13, 177, 39, 1);
  --color-typography-info: var(--color-blue-800);
  --color-typography-white: var(--color-white);
  --color-typography-alert: var(--color-red-300);
  --color-typography-alert1: var(--color-red-500);

  // ======== TOOLTIPS =========
  --color-tooltip-background: rgba(74, 85, 104, 1);

  // ======== SHADOWS ===========
  --shadows-x: 2px 4px 6px 0px rgba(33, 10, 81, 0.08);
  --shadows-2x: 2px 4px 12px 0px rgba(33, 10, 81, 0.08);
  --shadows-3x: 2px 10px 22px rgba(33, 10, 81, 0.1);
  --shadows-4x: 2px 14px 32px rgba(33, 10, 81, 0.14);
  --shadows-5x: 2px 34px 71px rgba(33, 10, 81, 0.2);
  --shadow-dropdown-menu: 1px 8px 40px rgba(11, 5, 22, 0.2);
}
