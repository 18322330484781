.button {
  display: flex;
  gap: var(--spacing-x);
  align-items: center;
  border-radius: 24px;
  background: none;
  color: inherit;
  border: none;
  padding: 0 var(--spacing-2x);
  font: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  outline: inherit;
  height: 48px;

  p {
    width: 100%;
  }
}

.secondary {
  background-color: transparent !important;
  border: 1px solid var(--color-red-500);
}
