@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.image {
  padding-bottom: 16px;
}

.title {
  font-family: $gt-america-bold;
  color: $gray-600;
  font-size: 1rem;
  text-align: center;
}

.subtitle {
  color: rgba(33, 33, 33, 0.6);
  font-size: 0.875rem;
  text-align: center;
}
