@import "../../../../assets/styles/variables";
  .remoteHiddenUser {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: $trula-black;
  }
  
  .remoteIdentity {
    height: 200px;
    width: 200px;
    font-size: 6rem;
    text-align: center;
    color: $teal;
    border: 3px solid $teal;
    border-radius: 50%;
    background-color: $trula-black;
    padding: 20px;
    padding-top: 35px;
    @media (min-width: 768px) {
      height: 120px;
      width: 120px;
      font-size: 4rem;
      padding-top: 17px;
    }
  }

.localHiddenUser {
  position: absolute;
  right: 3%;
  top: 2%;
  height: 25%;
  width: 40%;
  z-index: 1000;
  border-radius: 10px!important;
}

.localIdentity {
  height: 210px;
  width: 150px;
  font-size: 6rem;
  text-align: center;
  color: $teal;
  border: 3px solid $teal;
  border-radius: 10px;
  background-color: $trula-black;
  padding: 20px;
  padding-top: 35px;
}
  
  .muteRemoteIcon {
    position: absolute;
    top: 2%;
    left: 2%;
    height: 25%;
    width: 40%;
    @media (min-width: 768px) {
      top: 3%;
      left: 33%;
    }
  }