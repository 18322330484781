.container {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  cursor: pointer;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 44px;

  &:focus-within > .input:before {
    border: 2px solid rgba(var(--color-blue-800), .8);;
  }
}

.text {
  margin-left: 12px;
}

.input {
  display: flex;
  align-items: center;
  appearance: initial;
}

// Background
.input::before {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 30px;
  content: '';
  cursor: pointer;
  border-radius: 100px;
}

.checked::before {
  background-color: #d0d9e2;
}

.unchecked::before {
  background-color: #e1e1e1;
}

// The ball
.input::after {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: gray;
  border-radius: 50%;
  content: '';
  cursor: pointer;
  box-shadow: 1px 1px 3px rgba(13, 32, 111, 0.12);
}

.unchecked::after {
  transition: transform 0.3s;
  transform: translateX(0);
  background-color: var(--color-white);
}

.checked::after {
  transition: transform 0.3s;
  background-color: var(--color-blue-800);
  transform: translateX(128%);
}
