@import "/src/assets/styles/variables";

.emptyButtonContainer {
  display: flex;
  justify-content: center;
}

.emptyButton {
  border: 1px solid $salmon;
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 24px;
  width: fit-content;
  text-align: center;
}

.w100 {
  width: 100%;
}
