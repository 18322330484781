@import "../../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-0_5x);

  p:first-of-type {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: rgba(33, 33, 33, 0.6);
  }
}

.fullWidth {
  width: 100%;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 24px;
  flex-grow: 1;
  background: $white;
  border: 1px solid $light-smoke;
  border-radius: 8px;
  padding: 8px;
  outline: none;

  &:focus {
    outline: 2px solid var(--color-blue-800);
    outline-offset: -2px;
  }
}

label {
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 20px;
  color: $trula-smoke;
  margin-bottom: 4px;
}

.optional {
  font-size: 0.75rem;
  color: $gray-950;
  padding-top: 5px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
}
