@import "../../../../../assets/styles/variables";

.checkbox {
  position: relative;
  display: flex;
  font-family: $gt-america;
  border-bottom: 1px solid $light-smoke;
  padding: 18px 0 0 0;
  padding-bottom: 14px;
  gap: 8px;

  input,
  svg {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: block;
  }

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    margin: 0;
    padding: 0;
    margin-top: 2px;
    cursor: pointer;
    border-radius: 2px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, $dark-gray);

    &:checked {
      --b: #1c1b1f;
    }
  }

  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, $dark-gray);
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }

  &.path {
    input {
      &:checked {
        --s: 1px;
        transition-delay: 0.4s;

        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }

    svg {
      margin: 20px 0px;
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
    }
  }

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: $trula-black;
    margin-left: 3px;
    text-align: left;
  }
}

.bold {
  font-weight: 700;
}

.required {
  color: red !important;
}
