@import "../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.currentComponent {
  width: 100%;
  height: 90%;
  overflow: scroll;
  margin-bottom: 0;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.backButton {
  background-color: #f0f2f2;
  padding: 36px 24px 12px 24px;
}

.congratsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $teal;
  padding: 32px;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: rgba(33, 33, 33, 0.4);
  padding-bottom: 32px;
  padding-top: 0;
  margin: 0;
}

.nextButton {
  button {
    width: 100%;
  }
}

.centered {
  line-height: 1.5rem;
  font-size: 1rem;
  color: $dark-blue;
  font-family: $gt-america;
  text-align: center;
}
