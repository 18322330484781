@import "/src/assets/styles/variables";

.exportContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px 32px 0;
}

.title {
  padding-bottom: 56px;
  font-family: $gt-america-condensed-bold;
  font-size: 1.5rem;
}

.textCenter {
  text-align: center;
}

.actionButton {
  padding-top: 32px;
  margin: 0 auto;
}
