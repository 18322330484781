@import "../../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;

  div.showError {
    display: inline-block;
    width: auto;
    font-size: 0.75rem;
    line-height: 18px;
    color: $trula-smoke;
    margin-top: 4px;
    text-align: left;
  }

  div.hideError {
    display: none;
  }
}

.fullWidth {
  width: 100%;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 24px;
  flex-grow: 1;
  background: $white;
  border: 1px solid $light-smoke;
  border-radius: 60px;
  padding: 8px;
  outline: none;

  &:focus {
    outline: 2px solid var(--color-blue-800);
    outline-offset: -2px;
  }

  &.invalid {
    border: 1px solid $danger;
    background: $white;
    color: $trula-black;
    outline: none;
  }

  &.readOnly {
    background: $trula-white;
    cursor: auto;
  }

  &::placeholder {
    color: #212121;
    opacity: 0.3;
  }

  :-ms-input-placeholder {
    color: #212121;
    opacity: 0.3;
  }

  ::-ms-input-placeholder {
    color: #212121;
    opacity: 0.3;
  }
}

label {
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 20px;
  color: $trula-smoke;
  margin-bottom: 4px;
}

.optional {
  font-size: 0.75rem;
  color: $gray-950;
  padding-top: 5px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
}
