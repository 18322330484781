.container {
  background-color: var(--color-blue-800);
  height: 100vh;
  padding: var(--spacing-8x) var(--spacing-3x);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-3x);
}
