@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.sessionTime {
  display: flex;
  flex-direction: row;
  font-family: $gt-america;
  font-size: 1.375rem;
  line-height: 1.73rem;
  color: $dark-blue;
  text-align: center;
}

.sessionTime p {
  margin: 0;
}

.timeSeparator {
  padding: 0 7px;
}

.paddingBottom100 {
  padding-bottom: 100px;
}
