@import "/src/assets/styles/variables";

.textarea {
  width: 100%;
  border: 1px solid #bfd4db;
  border-radius: 8px;
  font-family: $gt-america;
  padding: 16px;
  resize: none;
  
  :focus {
    border: 1px solid #bfd4db;
  }
}

textarea {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}
