@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.tabLabel {
  font-family: $gt-america-condensed-bold;
  line-height: 20px;
  font-size: 0.875rem;
  opacity: 0.5;
  border-bottom: 2px solid #848484;
  flex: 1;
  text-align: center;
  padding-bottom: 16px;
  cursor: pointer;
}

.active {
  opacity: 1;
  border-bottom: 4px solid $teal;
}
