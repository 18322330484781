@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  background-color: $white;
  border-radius: 8px;
  padding: 18px;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.right {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.hour {
  font-family: $gt-america-bold;
  color: $dark-blue;
}

.date {
  font-size: 0.75rem;
}
