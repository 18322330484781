@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 24px;
  // padding: 64px 24px 0 24px;
  align-items: center;
}

.header {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 2rem;
  font-family: $gt-america-condensed-bold;
}

.subtitle {
  font-family: $gt-america;
  color: $dark-blue;
}

.button {
  width: calc(100% - 70px);
  position: absolute;
  bottom: 64px;
  display: flex;
  justify-content: center;
}

.image {
  padding-top: 36px;
}
