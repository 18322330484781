@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.listing {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.content {
  padding: 80px 0 80px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
