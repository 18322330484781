.container {
  position: relative;
  display: flex;
  user-select: none;
}

.content {
  position: fixed;
  top: 0;
  z-index: 1000;
  opacity: 0;
}

.contentHidden {
  opacity: 0;
  pointer-events: none;
  display: none;
}

.contentVisible {
  opacity: 1;
  transform: scale(1);
}

.backdrop {
  position: absolute;
  inset: 0;
  z-index: 999;
}

.disabled {
  cursor: default;
  pointer-events: none;
}
