@import "/src/assets/styles/variables";
.container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: $white;
  border-radius: 8px;
  margin: 2px;
}

.selected {
  outline: 2px solid $teal;
}

.percentage {
  // padding-left: 10px;
  font-size: 0.75rem;
  font-family: $gt-america;
  color: $trula-smoke;
  white-space: nowrap;
}

.languageLabel {
  font-family: $gt-america-bold;
}
