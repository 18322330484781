@import "../../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.currentComponent {
  width: 100%;
  margin-bottom: 0px;
}

.backButton {
  background-color: #f0f2f2;
  padding: 36px 24px 12px 24px;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: rgba(33, 33, 33, 0.4);
  padding-bottom: 42px;
  padding-top: 0px;
  margin: 0;
}

.nextButton {
  width: 100%;
  background-color: #f0f2f2;
  position: relative;
  // top: 74%÷;;
  padding-top: 48px;
  display: flex;
  justify-content: center;
}
