@import "/src/assets/styles/variables";
.container {
  display: flex;
  flex-direction: column;
}

.rescheduleSessionContainer {
  display: flex;
  justify-content: center;
}

.cardsContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 36px;
}

.rescheduleSession {
  border: 1px solid $salmon;
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 24px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}

.interestsBar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.cardUnorderedList {
  margin-top: 0;
  list-style-type: disc;
  padding: 0 20px;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
  margin-bottom: 24px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}
.separator::before,
.separator::after {
  content: "";
  padding: 0 12px;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  gap: 16px;
  align-items: center;
}

.cancelButton {
  align-self: center;
  cursor: pointer;
  font-weight: 700;
}

//* Modal styles
.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: $gt-america-condensed-bold;
  align-self: center;
  text-align: center;
  overflow-x: hidden;
  // height: 100%;
}

.modalTitle {
  font-size: 2rem;
  align-self: flex-start;
  color: $trula-smoke;
  margin: 0;
}

.modalSubtitle {
  font-size: 1rem;
  font-family: $gt-america;
  align-self: flex-start;
  line-height: 24px;
  color: rgba(33, 33, 33, 0.4);
  padding-bottom: 20px;
  margin: 0;
}

.modalButtonContainer {
  position: relative;
  margin-top: auto;
  padding-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coachInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.name {
  font-size: 1rem;
  color: $dark-teal;
  font-family: $gt-america-bold;
  line-height: 20px;
  padding: 0 30px;
  padding-top: 12px;
  text-align: center;
}

.matchPercentage {
  font-size: 0.75rem;
  font-family: $gt-america-condensed-bold;
  line-height: 16px;
  color: $dark-blue;
}

.plain {
  font-size: 0.85rem;
  font-family: $gt-america;
  font-weight: 400;
  line-height: 18px;
  padding: 0 30px;
  margin-bottom: 7px;
  color: $trula-black;
}

.comments {
  font-size: 14px;
  align-self: flex-start;
  margin-bottom: 5px;
}

.textarea {
  width: 100%;
  border: 1px solid #bfd4db;
  border-radius: 8px;
  font-family: $gt-america;
  padding: 16px;
  :focus {
    border: 1px solid #bfd4db;
  }
}

textarea {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.skipButton {
  font-family: $gt-america;
  margin-top: 14px;
}

.addToCalendarButton {
  width: 40px;
  height: 40px;
  background-color: $dark-blue;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    cursor: pointer;
  }
}
.topActions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
}

.calendarModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 16px;
}

.cancelLabel {
  margin-top: 16px;
  font-family: $gt-america-condensed-bold;
  cursor: pointer;
}

.googleCalendarLink {
  @media (max-width: 768px) {
    width: 100%;
  }
}

.cancelModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 16px;
  gap: 16px;
}

.cancelTitle {
  font-size: 1.5rem;
  font-family: $gt-america-condensed-bold;
}

.actions {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 768px) {
    padding: 0 25%;
    padding-top: 100px;
    width: 100%;
  }
}

.discard {
  font-family: $gt-america-condensed-bold;
  cursor: pointer;
}
