@import "../../../../../assets/styles/variables";

.selectableCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  line-height: 18px;
  color: $trula-black;
  background: $white;
  border: 2px solid transparent;
  border-radius: 60px;
  padding: 24px 16px;
  white-space: nowrap;
}

.selected {
  border: 2px solid var(--color-blue-800);
  color: var(--color-typography-info);
  font-weight: 700;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
