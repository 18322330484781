@import "/src/assets//styles/variables";

.blur {
  filter: blur(5px);
}

.emptyBackgroundButton {
  border: 1px solid $salmon;
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 24px;
  width: fit-content;
  margin-top: 24px;
  text-align: center;
  line-height: 16px;
  width: 100%;
}

.label {
  font-family: $gt-america-condensed-bold;
  font-size: 1.2rem;
  text-align: center;
  color: $dark-blue;
}

.greyLabel {
  opacity: 0.6;
  color: $trula-black;
}

.textarea {
  width: 100%;
  border: 1px solid #bfd4db;
  border-radius: 8px;
  font-family: $gt-america;
  padding: 16px;
  :focus {
    border: 1px solid #bfd4db;
  }
}

.w100 {
  width: 100%;
}
