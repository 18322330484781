@import "/src/assets/styles/variables";

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--color-typography-white);
  background: var(--color-blue-800);
  border: 1px solid var(--color-blue-800);
  border-radius: 24px;
  padding: 12px 24px;
  cursor: pointer;

  font-family: $gt-america-condensed-bold;

  &.focus {
    outline: none;
  }

  &.disabled {
    background: var(--color-blue-800);;
    opacity: 0.6;
    border-color: var(--color-blue-800);
    color: var(--color-typography-white);
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    max-width: 250px;
  }
}

.topShift {
  margin-top: 25px;
}

.saveButton {
  // position: absolute;
  width: calc(100% - 32px);
  bottom: 30px;
  left: 16px;
  @media (min-width: 768px) {
    width: 300px;
  }
  margin-top: 36px;
}

.verifyPhoneNumberButton {
  margin: 0 auto;
}
