@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.avatarSection {
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.editButton {
  border: 1px solid $salmon;
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 24px;
  width: fit-content;
  margin-top: 24px;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}

.edit {
  width: 24px;
  padding-left: 8px;
}

.nameContainer {
  font-size: 1rem;
  color: $dark-teal;
  font-family: $gt-america-bold;
  padding-top: 16px;
}

.plain {
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.aboutMeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  gap: 16px;
}

.sectionTitle {
  font-size: 0.625rem;
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
}

.greyTitle {
  font-size: 0.625rem;
  color: $trula-black;
  opacity: 0.5;
  font-family: $gt-america-condensed-bold;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

.card {
  background-color: $white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.cardTitle {
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
}

.interestsBar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.deleteAccountButton {
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  border-radius: 24px;
  border: 1px solid #bfd5db;
  padding: 16px;
  width: fit-content;
  align-self: center;
  margin: 36px 0;
  cursor: pointer;
}

.actionButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 36px 0 24px 0;
}

.editingLabel {
  font-family: $gt-america-bold;
  padding-bottom: 8px;
}

.editingSection {
  padding-bottom: 24px;
}

.content {
  padding: 0 16px;
}

.closeIcon {
  width: 14px;
  position: absolute;
  right: 21px;
  top: 21px;
}

.title {
  font-family: $gt-america-condensed-bold;
  font-size: 2rem;
  font-weight: 700;
  line-height: 40px;
  color: $smoke;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 8px;
  padding-top: 52px;

  @media (min-height: 100px) and (max-height: 625px) {
    font-size: 1.5rem;
  }
  @media (min-height: 626px) and (max-height: 820px) {
    font-size: 2rem;
  }
}

.subtitle {
  padding-top: 20px;
  font-family: $gt-america;
  font-size: 1rem;
  color: $dark-blue;
  padding-bottom: 24px;
}

.customEntries {
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
  color: $trula-black;
  width: 100%;
}

.saveButton,
.verify {
  position: absolute;
  width: calc(100% - 32px) !important;
  bottom: 16px;
  left: 16px;
}

.verify {
  bottom: 30px;
}

.customEntriesListing {
  overflow: auto;
  margin-bottom: 15px;
  max-height: 50vh;
  @media (min-height: 626px) and (max-height: 820px) {
    max-height: 30vh;
  }
  @media (min-height: 100px) and (max-height: 625px) {
    max-height: 20vh;
  }
}

.error {
  // display: inline-block;
  width: auto;
  font-size: 0.75rem;
  // line-height: 18px;
  color: $danger;
  margin-bottom: 16px;
  // margin-top: 4px;
}

.deleteModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 16px;
  gap: 16px;
}

.deleteTitle {
  font-size: 1.5rem;
  font-family: $gt-america-condensed-bold;
}

.blurred {
  filter: blur(10px);
}

.actions {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 768px) {
    padding: 0 25%;
    padding-top: 100px;
    width: 100%;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.infoMessage {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  padding-bottom: 32px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.discard,
.changeNumberButton {
  font-family: $gt-america-condensed-bold;
  cursor: pointer;
}

.changeNumberButton {
  margin-top: 15px;
}

.textCentered {
  text-align: center;
}

.confirmNumberLabel {
  font-family: $gt-america-bold;
  color: $black;
}
