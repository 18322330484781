@import "../../../../assets/styles/variables";

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
  padding: 12px 0;
  margin: 0 24px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.separator:not(:empty)::before {
  margin-right: 0.875em;
}

.separator:not(:empty)::after {
  margin-left: 0.875em;
}

.phoneNo {
  &::placeholder {
    color: 212121;
    opacity: 0.3;
  }

  :-ms-input-placeholder {
    color: 212121;
    opacity: 0.3;
  }

  ::-ms-input-placeholder {
    color: 212121;
    opacity: 0.3;
  }
}
