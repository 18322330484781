@import "../../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-0_5x);

  p:first-of-type {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: rgba(33, 33, 33, 0.6);
  }
}
