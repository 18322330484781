@import "/src/assets/styles/variables";

.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 250px);
  overflow: auto;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
