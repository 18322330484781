@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 96px);
  overflow: auto;
}

.avatarSection {
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.editButton {
  border: 1px solid $salmon;
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 24px;
  width: fit-content;
  margin-top: 16px;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
}

.edit {
  width: 24px;
  padding-left: 8px;
}

.nameContainer {
  font-size: 1rem;
  color: $dark-teal;
  font-family: $gt-america-bold;
  padding-top: 16px;
}

.plain {
  font-size: 0.875rem;
  line-height: 20px;
}

.aboutMeContainer,
.moreAboutMeContainer,
.biographyContainer,
.personalityContainer,
.interestsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  gap: 16px;
}

.sectionTitle {
  font-size: 0.625rem;
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
  line-height: 10px;
  letter-spacing: 0.02em;
}

.greyTitle {
  font-size: 0.75rem;
  color: $trula-black;
  opacity: 0.5;
  font-family: $gt-america-condensed-bold;
  line-height: 15px;
  letter-spacing: 0.04em;
}

.card {
  background-color: $white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}

.cardTitle {
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
}

.interestsBar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.actionButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 36px 0 24px 0;
}

.editingLabel,
.editingAboutLabel {
  font-family: $gt-america-bold;
  padding-bottom: 8px;
}

.editingAboutLabel {
  margin-top: 24px;
}

.editingSection {
  padding-bottom: 24px;
}

.content {
  padding: 0 16px;
}

.closeIcon {
  width: 14px;
  position: absolute;
  right: 21px;
  top: 21px;
}

.title {
  font-family: $gt-america-condensed-bold;
  font-size: 2rem;
  font-weight: 700;
  line-height: 40px;
  color: $smoke;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 8px;
  padding-top: 52px;

  @media (min-height: 100px) and (max-height: 625px) {
    font-size: 1.5rem;
  }
  @media (min-height: 626px) and (max-height: 820px) {
    font-size: 2rem;
  }
}

.subtitle {
  padding-top: 20px;
  font-family: $gt-america;
  font-size: 1rem;
  color: $dark-blue;
  padding-bottom: 24px;
}

.customEntries {
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
  color: $trula-black;
  width: 100%;
}

.saveButton,
.verify {
  position: absolute;
  width: calc(100% - 32px) !important;
  bottom: 16px;
  left: 16px;
}

.verify {
  bottom: 30px;
}

.customEntriesListing {
  overflow: auto;
  margin-bottom: 15px;
  max-height: 50vh;
  @media (min-height: 626px) and (max-height: 820px) {
    max-height: 30vh;
  }
  @media (min-height: 100px) and (max-height: 625px) {
    max-height: 20vh;
  }
}

.error {
  // display: inline-block;
  width: auto;
  font-size: 0.75rem;
  // line-height: 18px;
  color: $danger;
  margin-bottom: 16px;
  // margin-top: 4px;
}

.actions {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.discard {
  font-family: $gt-america-condensed-bold;
  cursor: pointer;
}

.calendar {
  position: absolute;
  right: 20px;
  right: 10px;
  bottom: 22px;
}

.birthdate {
  margin-bottom: 14px;
}
