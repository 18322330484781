@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 32px;
  background-color: $white;
  border-radius: 12px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timeslotContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.625rem;
  line-height: 13px;
  letter-spacing: 0.04em;
  color: $trula-black;
}

.timeslotContainer span:last-of-type {
  color: $dark-blue;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
  padding: 12px 0;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}
.separator::before,
.separator::after {
  content: "";
  padding: 0 12px;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.messageInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.studentInfo {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.avatar {
  border-radius: 100%;
  height: 32px;
  width: 32px;
}

.name {
  display: flex;
  align-items: center;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: $dark-teal;
}

.messageText {
  font-size: 0.875rem;
  line-height: 20px;
  color: $dark-blue;
}
