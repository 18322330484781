.base {
  font-family: "GT-America", sans-serif;
  margin: 0;
}

.bold {
  font-family: "GT-America-Condensed-Bold", sans-serif;
}

.primary {
  color: var(--color-typography-primary);
}

.secondary {
  color: var(--color-typography-secondary);
}

.accent {
  color: var(--color-typography-accent);
}

.info {
  color: var(--color-typography-info);
}

.success {
  color: var(--color-typography-success);
}

.success1 {
  color: var(--color-typography-success1);
}

.label {
  color: var(--color-typography-success1);
}

.white {
  color: var(--color-typography-white);
}

.alert {
  color: var(--color-typography-alert);
}

.alert1 {
  color: var(--color-typography-alert1);
}

.title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}

.title2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.title3 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

.subtitle1 {
  font-size: 24px;
  line-height: 20px;
  font-weight: 700;
}

.subtitle2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.subtitle3 {
  font-size: 14px;
  line-height: 17.61px;
  font-weight: 700;
}

.body1 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.body2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.button {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.link {
  font-size: 14px;
  line-height: 17.61px;
  font-weight: 700;
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}
