.container {
  display: flex;
  cursor: pointer;
  position: relative;
  user-select: none;
}

.overlay {
  background-color: var(--color-tooltip-background);
  box-shadow: var(--shadows-2x);
  padding: 0.5rem 0.8rem;
  border-radius: var(--border-radius-base);
  margin: 8px 0;

  &::after {
    content: '';
    position: absolute;
    max-width: 0;
    max-height: 0;
    z-index: 2;
    pointer-events: none;
    border: 4px solid transparent;
  }

  &.top-left::after {
    left: 5px;
    border-top-color: var(--color-tooltip-background);
    top: calc(100% - 8px);
  }

  &.top-right::after {
    right: 5px;
    border-top-color: var(--color-tooltip-background);
    top: calc(100% - 8px);
  }

  &.top::after {
    left: 0;
    right: 0;
    margin: auto;
    border-top-color: var(--color-tooltip-background);
    top: calc(100% - 8px);
  }

  &.top-end::after {
    right: 5px;
    border-top-color: var(--color-tooltip-background);
    top: calc(100% - 8px);
  }

  &.bottom-end::after {
    top: 0;
    right: 5px;
    border-bottom-color: var(--color-tooltip-background);
  }

  &.bottom-start::after {
    top: 0;
    left: 5px;
    border-bottom-color: var(--color-tooltip-background);
  }

  &.bottom::after {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-bottom-color: var(--color-tooltip-background);
  }

  &.left::after {
    border-left-color: var(--color-tooltip-background);
    right: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &.right::after {
    border-right-color: var(--color-tooltip-background);
    left: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.disabled {
  cursor: default;
}
