.container {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  height: 100vh;
  max-height: 100vh;
  padding-bottom: var(--spacing-4x);
}

.header {
  height: 80px;
  border-bottom: 1px solid var(--color-gray-100);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  align-content: center;
  gap: var(--spacing-4x);
  padding: 0 var(--spacing-4x);
}

.filters {
  display: flex;
  align-items: center;
  gap: var(--spacing-2x);
  border-bottom: 1px solid var(--color-gray-100);
  margin: var(--spacing-4x) var(--spacing-4x) 0;
  padding: var(--spacing-x) var(--spacing-2x) var(--spacing-4x);
}

.input {
  background-color: transparent;
  border-radius: 24px;
  max-width: 720px;
}

.content {
  overflow: auto;
  position: relative;
  padding: var(--spacing-2x) var(--spacing-4x) 0;
}
