@import "/src/assets/styles/variables";

h2 {
  font-family: $gt-america-condensed-bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 0;
}

.loading:after {
  content: " .";
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: $white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 $white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 $white, 0.5em 0 0 $white;
  }
}
