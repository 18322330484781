.container {
  position: relative;

  :global .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 32px;
  }

  & > span {
    position: absolute;
    top: 44px;
  }

  &.noToolbar {
    & > span {
      position: absolute;
      top: -15px;
    }
  }
}
