@import "../../../../../assets/styles/variables";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 0.875rem;
  font-family: "Source Sans 3", sans-serif;
  text-align: left;
}

.maskedInput {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 24px;
  background: $white;
  border: 1px solid $light-smoke;
  border-radius: 40px;
  padding: 8px;
  outline: none;

  &.invalid {
    border: 1px solid $danger;
    background: $white;
    color: $trula-black;
    outline: none;
  }

  &:focus,
  &:focus-visible {
    border: 1px solid var(--color-blue-800);
  }
}

.error {
  display: inline-block;
  width: 100%;
  font-size: 0.75rem;
  line-height: 18px;
  color: $trula-smoke;
  margin-top: -10px;
  margin-bottom: 16px;
  text-align: left;
}
