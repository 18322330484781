.container {
  padding: var(--spacing-4x) var(--spacing-2x);
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4x);
  max-width: 448px;
  min-width: 375px;
  margin: auto;

  @media (max-width: 448px) {
    max-width: unset;
  }
}

.header {
}

.contentContainer {
  flex: 1;
  position: relative;
}

.content {
  position: absolute;
  inset: 0;
  overflow: auto;
  -webkit-appearance: none;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.footer {
  display: flex;
  gap: var(--spacing-3x);
  justify-content: center;

  button {
    max-width: unset;
  }
}

.backButton {
  padding-bottom: 12px;
}
