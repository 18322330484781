@mixin desktop() {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin defineRGBColor($name, $value) {
  $red: red($value);
  $green: green($value);
  $blue: blue($value);

  --color-#{$name}: rgb(#{$red}, #{$green}, #{$blue});
  --color-#{$name}-rgb: #{$red}, #{$green}, #{$blue};
}
