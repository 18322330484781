@import "/src/assets/styles/variables";
.container {
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  align-items: center;
  overflow: auto;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.name {
  font-size: 1rem;
  color: $dark-teal;
  font-family: $gt-america-bold;
  padding-top: 16px;
  text-align: center;
}

.matchPercentage {
  padding-top: 4px;
  font-size: 0.75rem;
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
}

.basicInfo {
  color: $trula-black;
  font-size: 0.75rem;
}

.module {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-bottom: 8px;
  width: 100%;
  text-align: left;
}

.darkLabel {
  font-size: 0.625rem;
  font-family: $gt-america-condensed-bold;
  line-height: 10px;
  color: $dark-blue;
  letter-spacing: 0.02em;
}

.lightLabel {
  font-size: 0.75rem;
  color: $trula-black;
  line-height: 15px;
  opacity: 0.5;
  font-family: $gt-america-condensed-bold;
  letter-spacing: 0.04em;
}

.plain {
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 0.04em;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}
.separator::before,
.separator::after {
  content: "";
  padding: 0 12px;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.pb8 {
  padding-bottom: 8px;
}

.pb16 {
  padding-bottom: 16px;
}
.pb24 {
  padding-bottom: 24px;
}

.listing {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}
