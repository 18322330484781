@import "../../../../assets/styles/variables";

.container {
  display: grid;
  grid-template-rows: 1fr max-content;
  background-color: var(--color-blue-800);
  height: 100vh;
  justify-content: center;
  justify-items: center;
  align-items: center;

  p {
    font-family: $gt-america;
    font-size: 1.25rem;
    line-height: 28px;
    text-align: center;
    padding: 0 24px;
    margin-top: 8px;
  }
}

.text {
  & > * {
    text-align: center;
    color: var(--color-typography-white);
  }

  p {
    max-width: 327px;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 64px;
  }
}

.circles {
  height: 180px;
  width: 180px;
  position: relative;

  > div {
    animation: growAndFade 3s infinite ease-out;
    background-color: dodgerblue;
    border-radius: 50%;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .circle2 {
    animation-delay: 1s;
  }

  .circle3 {
    animation-delay: 2s;
  }
}

@keyframes growAndFade {
  0% {
    opacity: .25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
