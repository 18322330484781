.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding-bottom: var(--spacing-4x);
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.body {
  flex: 1;
  position: relative;
}

.content {
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-3x);
  padding-top: var(--spacing-4x);
}
