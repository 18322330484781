@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background-color: $white;
  border-radius: 8px;
  gap: 26px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timeslotContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 0.6rem;
  line-height: 13px;
  letter-spacing: 0.04em;
  color: $dark-blue;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
  padding: 12px 0;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}
.separator::before,
.separator::after {
  content: "";
  padding: 0 12px;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.messageInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.messageText {
  font-size: 0.875rem;
  line-height: 20px;
  color: $dark-blue;
}

.separatorWhite {
  color: $trula-white;
}

.separatorWhite::before,
.separatorWhite::after {
  content: "";
  border-bottom: 2px solid $trula-white;
}
.separatorWhite::before,
.separatorWhite::after {
  content: "";
  border-bottom: 2px solid $trula-white;
}

.containerBlue {
  background-color: rgba(191, 212, 219, 0.4);
}
