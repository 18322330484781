.td {
  cursor: default;
}

.td[data-cell-is-clickable] {
  cursor: pointer;
  position: relative;

  a {
    display: flex;
    align-items: center;

    :before {
      content: '';
      position: absolute;
      inset: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    border-radius: 2px;
  }
}


.rowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
}
