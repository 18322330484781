.container {
  cursor: pointer;
  display: grid;
  row-gap: 1px;
  padding-bottom: 0.2rem;
  user-select: none;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--color-white);

  & > div:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-100);
  }
}

.header {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-gray-100);
  cursor: default;
}
