@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: 26px 0;
  max-height: calc(100vh - 250px);
}

.conversationsListing {
  gap: 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.dayLabel {
  text-transform: capitalize;
  font-family: $gt-america-bold;
  font-size: 1.125rem;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: $light-smoke;
}

.dateBlock {
  display: flex;
  flex-direction: column;
}

.chatsBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
}
