@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.avatar {
  width: 96px;
  height: 96px;
  border-radius: 100%;
}

.timeslotsContainer {
  margin-top: 36px;
}

.listingContainer {
  margin-top: 24px;
  @media (max-width: 768px) {
    max-height: 300px;
  }
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.lightLabel {
  color: $trula-black;
  opacity: 0.5;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: $gt-america;
  text-align: left;
}
