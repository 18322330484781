.container {
  padding: var(--spacing-2x);
  position: relative;

  h2 {
    font-size: 32px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
  }

  :global .fc-header-toolbar {
    margin-bottom: var(--spacing-6x) !important;
  }

  :global .fc-event-title {
    white-space: pre-line !important;
    text-align: left !important;
    padding: 24px 0;
  }

  .toggleWrapper {
    position: absolute;
    top: 62px;
  }
}
