@import "../../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  text-align: left;

  div[class$="-control"] {
    border-radius: 40px;
  }

  div[class$="-menu"] {
    margin: 4px 2px 0;
    width: calc(100% - 4px);
  }

  div[class$="-ValueContainer"] {
    padding: 8px 16px;
  }
}

.label {
  padding-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: "Source Sans 3", sans-serif;
  color: $trula-smoke;
  margin: unset;
}

.select {
  width: 100%;
  border-radius: 8px;
  font-family: $gt-america;
  font-size: 0.875rem;
}

.error {
  display: inline-block;
  width: auto;
  font-size: 0.75rem;
  line-height: 18px;
  color: $trula-smoke;
  margin-top: 4px;
}
