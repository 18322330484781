.container {
  padding: 0.6rem 1rem;

  &:hover {
    background-color: var(--color-gray-200);
  }
}

.link {
  padding: 0.6rem 1rem;
}

.textItem {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  align-content: center;
  align-items: center;
  font-weight: 500;
}

.multiColumn {
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
}

.selected {
  background-color: var(--color-smoke-500);
  border-radius: 0.4rem;
  margin: 0 0.5rem 4px;
  cursor: default;

  &:hover {
    background-color: var(--color-smoke-500);
  }
}
