.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  row-gap: 16px;
}

.phoneNo {
  &::placeholder {
    color: 212121;
    opacity: 0.3;
  }

  :-ms-input-placeholder {
    color: 212121;
    opacity: 0.3;
  }

  ::-ms-input-placeholder {
    color: 212121;
    opacity: 0.3;
  }
}
