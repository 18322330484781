@import "/src/assets/styles/variables";
.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
}

.p16 {
  padding: 16px;
}

.title {
  font-family: $gt-america-condensed-bold;
  color: $dark-blue;
  font-size: 0.875rem;
}

.subtitle {
  font-size: 0.75rem;
  color: $trula-black;
  opacity: 0.6;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content {
  padding-top: 8px;
  font-size: 0.8rem;
}

.na {
  opacity: 0.6;
}
