@import "../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal {
  height: 315px;
  width: 340px;
}

.currentComponent {
  width: 100%;
  margin-bottom: 0;
}

.backButton {
  background-color: #f0f2f2;
  padding: 36px 24px 12px 24px;
}

.congratsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $teal;
  padding: 32px;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: rgba(33, 33, 33, 0.4);
  padding-bottom: 32px;
  padding-top: 0px;
  margin: 0;
}

.nextButton {
  width: 100%;
  background-color: #f0f2f2;
  padding: 12px 24px 24px 24px;
  position: relative;
  bottom: -12px;
  display: flex;
  justify-content: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.infoMessage {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.6);
  padding-bottom: 32px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.discard {
  margin-top: 15px;
  font-family: $gt-america-condensed-bold;
  cursor: pointer;
}

.confirmNumberLabel {
  font-family: $gt-america-bold;
  color: $black;
}
