@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-3x) var(--spacing-2x);
  height: 100vh;
  border: 1px solid #e5e5e5;
  position: relative;
  max-width: 768px;
  margin: auto;
}

.banner {
  height: 32px;

  .bannerContent {
    background-color: var(--color-typography-success1);
    color: var(--color-typography-white);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: underline;
      margin-left: 6px;
      margin-right: 6px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}

.title {
  font-size: 1.5rem;
  font-family: $gt-america-condensed-bold;
  line-height: 2rem;
}

.mt10 {
  margin-top: 10px;
}

.verifyTitle {
  font-family: $gt-america-condensed-bold;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $smoke;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 8px;
  @media (min-height: 626px) and (max-height: 820px) {
    font-size: 2rem;
  }

  @media (min-height: 100px) and (max-height: 625px) {
    font-size: 1.5rem;
  }
}

.center {
  text-align: center;
}

.menuWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.menuIcon {
  width: 18px;
  height: 12px;
  position: relative;
  cursor: pointer;
}

.conversationIcon {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}

.content {
  padding-top: var(--spacing-3x);
  height: 100%;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

.dropdown {
  position: absolute;
  top: 70px;
  right: 21px;
  width: 180px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
  border-radius: 0 0 5px 5px;
  cursor: pointer;
}

.dropdownItem {
  height: 48px;
  align-self: stretch;
  padding: 12px 8px;
  border-bottom: 1px solid $gray-menu;
}

.dropdownItem:last-child {
  border: none;
  border-radius: 0 0 5px 5px;
}

@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-2x);

  // height: calc(100vh - 170px);
}

.title {
  font-size: 1.5rem;
  font-family: $gt-america-condensed-bold;
  line-height: 2rem;
}

.mt10 {
  margin-top: 10px;
}

.verifyTitle {
  font-family: $gt-america-condensed-bold;
  font-size: 2rem;
  font-weight: 700;
  line-height: 40px;
  color: $smoke;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 8px;
  @media (min-height: 626px) and (max-height: 820px) {
    font-size: 2rem;
  }
  @media (min-height: 100px) and (max-height: 625px) {
    font-size: 1.5rem;
  }
}

.center {
  text-align: center;
}

.menuIcon {
  width: 18px;
  height: 12px;
  position: relative;
  cursor: pointer;
}

.conversationIcon {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}

.content {
  width: 100%;
}

.dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  width: 180px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
  border-radius: 0 0 5px 5px;
  cursor: pointer;

  :hover {
    // background-color: green;
    background-color: $dark-blue;
    color: $white;
  }
}

.dropdownItem {
  height: 48px;
  align-self: stretch;
  padding: 12px 8px;
  border-bottom: 1px solid $gray-menu;
}

.dropdownItem:last-child {
  border: none;
  border-radius: 0 0 5px 5px;
}

.textarea {
  width: 100%;
  border: 1px solid #bfd4db;
  border-radius: 8px;
  font-family: $gt-america;
  padding: 16px;

  :focus {
    border: 1px solid #bfd4db;
  }
}

textarea {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.modalButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    align-items: center;
  }
}

.skipButton {
  font-family: $gt-america;
  align-self: center;
  margin-top: 14px;
  cursor: pointer;
}

.topRightActions {
  display: flex;
  gap: 24px;
}

.modal {
  max-width: 327px;

  div[class^="EmptyModal_title"] {
    text-align: left;
  }
}

.modalContainer {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr max-content;

  textarea {
    max-height: 198px;
    resize: none;
  }
}
