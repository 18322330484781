@import "/src/assets/styles/variables";

.container {
  font-size: 0.875rem;
  color: $trula-black;
  font-family: $gt-america-bold;
  background-color: transparent;
  border: 1px dashed var(--color-blue-200);
  border-radius: 60px;
  padding: 22px 16px;
  margin-top: 16px;
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
}

.round {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-x);
  background-color: var(--color-white);
  border: 1px solid var(--color-white);
  font-weight: 400;

  svg {
    width: 24px;
    height: 24px;
    margin-right: unset !important;

    path {
      fill: var(--color-blue-800);
    }
  }
}
