@import "/src/assets/styles/variables";
.container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  background-color: $trula-white;
  border-radius: 8px;
  font-size: 0.75rem;
  padding: 4px 8px;
}

.dark {
  background-color: $dark-blue;
  color: $white;
}
