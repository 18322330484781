@import "/src/assets/styles/variables";

.content {
  padding-top: 36px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.actionButton {
  border-radius: 100%;
  padding: 8px;
  color: $black;
  height: 36px;
  width: 36px;
  background-color: $trula-white;
  text-align: center;
}

.textarea {
  width: 100%;
  border: 1px solid #bfd4db;
  border-radius: 8px;
  font-family: $gt-america;
  padding: 16px;
  :focus {
    border: 1px solid #bfd4db;
  }
}

textarea {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.noteDate {
  font-size: 0.75rem;
  font-family: $gt-america;
  color: $dark-blue;
  letter-spacing: 0.04em;
  line-height: 13px;
  border-bottom: 1px solid #dddd;
  padding-bottom: 8px;
}

.notesContainer {
  padding-top: 8px;
  list-style: disc;
  margin-left: 24px;
}

.note {
  color: $trula-black;
  font-size: 1rem;
  font-family: $gt-america;
  line-height: 24px;
}

.themesContainer {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.themesHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.themesListing {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  padding-top: 12px;
}

.themeListingModal {
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  height: auto;
  max-height: calc(100% - 120px);
}

.escalateButton {
  display: flex;
  justify-content: center;
  border-radius: 100%;
  background-color: $salmon;
  height: 36px;
  width: 36px;
}

.escalateButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 12px;
}

.escalation {
  color: $danger;
  text-align: center;
  font-family: $gt-america-condensed-bold;
}

.label {
  font-size: 0.9rem;
  font-family: $gt-america-condensed-bold;
  color: $yellow;
}

.nextButton {
  // margin-top: 30px;
  display: flex;
  justify-content: center;
  padding: 36px 0;
  // margin-bottom: 36px;
}

.addToCalendarButton {
  width: 40px;
  height: 40px;
  background-color: $dark-blue;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.topActions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 16px;
}

.calendarModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 16px;
}

.cancelLabel {
  margin-top: 16px;
  font-family: $gt-america-condensed-bold;
  cursor: pointer;
}

.googleCalendarLink {
  @media (max-width: 768px) {
    width: 100%;
  }
}
