@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.greyTitle {
  font-size: 0.825rem;
  color: $trula-black;
  opacity: 0.5;
  font-family: $gt-america-condensed-bold;
  padding-bottom: 4px;
}

.plain {
  font-size: 0.875rem;
  line-height: 20px;
}

.aboutMeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  gap: 16px;
}

.sectionTitle {
  font-size: 0.625rem;
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}
.interestsBar {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.surveyButton {
  border: 1px solid $salmon;
  font-family: $gt-america-condensed-bold;
  font-size: 1rem;
  padding: 12px 16px;
  border-radius: 24px;
  width: fit-content;
  margin: auto;
  margin-top: 16px;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  cursor: pointer;
}

.survey {
  width: 24px;
  padding-left: 8px;
  margin-bottom: -5px;
}

