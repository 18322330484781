.rowTr {
  cursor: grab;
}

.rowTr.disabled {
  cursor: not-allowed;
}

.rowWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
}
