.container {
  display: grid;
  grid-template-columns: 80px 1fr;
  height: 100vh;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid rgba(33, 33, 33, 0.08);
  align-items: center;
  padding: var(--spacing-2x) 0;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-2x);
  align-items: center;

  a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
  }
}

.content {
}

.active {
  background-color: var(--color-teal);
}
