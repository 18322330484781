@import "../../../../assets/styles/variables";

.room {
  height: 100vh;
  background-color: $trula-black;
}

.remoteMedia {
  background-color: $trula-black;
}

.localMedia {
  background-color: $trula-black;
}

.remoteMedia video {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: $trula-black;
}

.localMedia video {
  position: absolute;
  right: 3%;
  top: 2%;
  height: 25%;
  width: 40%;
  z-index: 10;
  border-radius: 10px;
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 92%;
  bottom: 3%;
  left: 4%;
  background-color: $gray-medium;
  border-radius: 16px;
  padding: 7px 20px;
  padding-bottom: 20px;
  gap: 10px;
  @media (min-width: 768px) {
    width: 50%;
    left: 25%;
    padding: 5px 15px;
    padding-bottom: 10px;
    gap: 5px;
  }
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 70%;
  color: $white;
  gap: 20px;
  @media (min-width: 768px) {
    width: 100%;
  }
}

.identity {
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 20px;
  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding: 0;
    line-height: 20px;
  }
}

.duration {
  font-size: 0.875;
  line-height: 18px;
}

.avatar {
  width: 1280px;
  height: 1280px;
  border-radius: 100%;
  z-index: 99;
}

.waitingNote {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 40%;
  color: $trula-white;
}
