.container {
  width: 100%;

  p {
    font-size: 0.875rem;
    line-height: 22px;
    margin-top: 0px;
  }

  h3,
  h4 {
    text-align: left;
  }

  li {
    text-align: left;
  }
}
