.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.calendar {
  position: absolute;
  right: 14px;
  bottom: 27px;
}

.customSchool {
  color: #d66361;
  line-height: 20px;
  width: 100%;
  text-align: right;
  font-size: 0.875rem;
  text-decoration: underline;
}

.birthdate {
  margin-bottom: 14px;
}
