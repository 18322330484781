@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 50px;
  margin-bottom: 36px;
  @media (min-height: 100px) and (max-height: 668px) {
    height: 50px;
  }
  margin-top: 36px;
}

.verifyContainer {
  & > div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: var(--spacing-2x);
  }
}

.codeInput {
  width: inherit;
  height: 42px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  font-family: $gt-america;
  border: 1px solid #d6d6d6;
  border-radius: 40px;
  background: #fff;

  &:focus,
  &:focus-visible
  &:active {
    outline: 2px solid var(--color-blue-800);
    outline-offset: -2px;
  }
}

.codeInput:last-child {
  margin-right: 0;
}

.button {
  text-align: end;
  text-decoration: underline;
  background-color: #edf2f6;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  max-width: 600px;
}
