@import "/src/assets/styles/variables";

.greyTitle {
  font-size: 0.825rem;
  color: $trula-black;
  opacity: 0.5;
  font-family: $gt-america-condensed-bold;
  padding-bottom: 4px;
}

.plain {
  font-size: 0.875rem;
  line-height: 20px;
  width: 100%;
}

.aboutMeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px;
  gap: 16px;
}

.sectionTitle {
  font-size: 0.625rem;
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

.card {
  background-color: $white;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.cardTitle {
  color: $dark-blue;
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
  text-transform: uppercase;
}
