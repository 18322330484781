.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sessionCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 0 4px;

  & > span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
