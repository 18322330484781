@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
}

.listingContainer {
  gap: 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 24px;
}

.dayLabel {
  text-transform: uppercase;
  font-family: $gt-america-bold;
  opacity: 0.6;
  color: $trula-black;
}

.dateBlock {
  display: flex;
  flex-direction: column;
}

.sessionBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 12px;
}

.additionalPadding {
  padding-top: 30px;
}

.sessionCell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 4px;

  & > span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
