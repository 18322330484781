@import "/src/assets/styles/variables";
.container {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  gap: 38px;
  line-height: 22px;
  text-align: left;
}

.numbering {
  color: $dark-teal;
  font-size: 2rem;
}
