@import "../../../../../assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0;

  div.showError {
    display: inline-block;
    width: auto;
    font-size: 0.75rem;
    line-height: 18px;
    color: $trula-smoke;
    margin-top: 4px;
    text-align: left;
  }

  div.hideError {
    display: none;
  }
}

.labelContainer {
  display: flex;
  flex-direction: row;
}

label {
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 20px;
  color: $trula-smoke;
  margin-bottom: 4px;
}

.password {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 24px;
  flex-grow: 1;
  background: $white;
  border: 1px solid $light-smoke;
  border-radius: 60px;
  padding: 8px;
  outline: none;
  width: 100%;

  &:focus {
    outline: 2px solid var(--color-blue-800);
    outline-offset: -2px;
  }

  &.invalid {
    border: 1px solid $danger;
    background: $white;
    color: $trula-black;
    outline: none;
  }

  &::placeholder {
    color: #212121;
    opacity: 0.3;
  }

  :-ms-input-placeholder {
    color: #212121;
    opacity: 0.3;
  }

  ::-ms-input-placeholder {
    color: #212121;
    opacity: 0.3;
  }
}

.toogleBtn {
  text-decoration: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 8px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.progressDiv {
  width: 100%;
  height: 0.25rem;
  background-color: #fbfbfb;
  border-radius: 0 0 0.2rem 0.2rem;
  margin-top: var(--spacing-x);
}

.progress {
  width: 0;
  height: 0.25rem;
  position: absolute;
  border-radius: 0 0 0.2rem 0.2rem;
  transition: all 0.5s ease-out;
}

.errorInfo {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #d9d9d9;
  opacity: 0.6;
  margin-top: 8px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
}

.listItem {
  font-size: 12px;
  line-height: 16px;
  color: $trula-black;
  opacity: 0.6;
  text-align: left;
}

.listItem span {
  margin-left: 5px;
}

.valid {
  text-decoration: line-through;
  list-style-type: none;
}

.checkIcon {
  width: 10px;
  height: 8px;
}
