.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: rgba(33, 33, 33, 0.4);
  margin-bottom: -5px;
}
