@import "/src/assets/styles/variables";

.container {
  background-color: $white;
  border-radius: 1rem;
  overflow: auto;
  position: relative;
}

.closeIcon {
  width: 14px;
  position: absolute;
  right: 21px;
  top: 21px;
  cursor: pointer;
}

.carouselButton {
  position: fixed;
  width: 36px;
  height: 36px;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 45%;
  background-color: $trula-white;
  border-radius: 16px;
}

.carouselButton:active {
  background-color: $teal;
  transition: 130ms ease-in-out;
}

.previous {
  left: 7px;
}

.next {
  right: 7px;
}

.previousImage {
  transform: rotate(180deg);
}
.image {
  width: 9px;
  height: 16px;
}
.dotsContainer {
  text-align: center;
  position: relative;
}
.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: $light-smoke;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.selected {
  background-color: $teal;
  // width: 24px;
  border-radius: 4px;
}
