.content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 600px;
  z-index: 4;
  background-color: rgba(249, 250, 252, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: var(--spacing-4x);

  div[class^="table_tableContainer"] {
    max-height: 82vh;
  }

  table {
    th {
      background-color: rgba(249, 250, 252, 1);
    }
  }

  :global .fc-header-toolbar {
    margin-bottom: var(--spacing-2x) !important;
    padding-top: var(--spacing-0_5x);
  }
}

.backdrop {
  background-color: rgba(33, 33, 33, 0.2);
  position: fixed;
  inset: 0;
  z-index: 3;
}
