@import "/src/assets/styles/variables";

.container {
  background-color: #fff;
  height: calc(100% - 64px);
  width: calc(100% - 48px);
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 24px;
  top: 32px;
  border-radius: 16px;
  z-index: 3;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  @media (min-width: 768px) {
    width: 600px;
    padding: 0 100px;
    padding-bottom: 50px;
    height: auto;
    // padding-bottom: 350px;
  }

  @media (min-width: 992px) {
    // width: 800px;
    height: auto;
    padding-bottom: 50px;
  }
}

.content {
  padding: 0 16px;
}

.closeIcon {
  width: 14px;
  position: absolute;
  right: 21px;
  top: 21px;
}

.title {
  font-family: $gt-america-condensed-bold;
  font-size: 2rem;
  font-weight: 700;
  line-height: 40px;
  color: $smoke;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 8px;
  padding-top: 52px;
  text-align: left;

  @media (min-height: 100px) and (max-height: 625px) {
    font-size: 1.5rem;
  }
  @media (min-height: 626px) and (max-height: 820px) {
    font-size: 2rem;
  }
}

.subtitle {
  padding-top: 8px;
  font-family: $gt-america;
  font-size: 1rem;
  color: $dark-blue;
  text-align: left;
  padding-bottom: 24px;
}

.customEntries {
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
  color: $trula-black;
  text-align: left;
  width: 100%;
}

.customEntriesListing {
  overflow: auto;
  margin-bottom: 15px;
  max-height: 50vh;
  @media (min-height: 626px) and (max-height: 820px) {
    max-height: 30vh;
  }
  @media (min-height: 100px) and (max-height: 625px) {
    max-height: 20vh;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
