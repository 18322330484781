.th {
  position: relative;
}

.reorderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.reorderWrapper.disabled {
  cursor: not-allowed;
}

.reorderWrapper.active {
  border-radius: 4px;
  background-color: #f5f5f5;
}
