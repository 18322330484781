.container {
  position: relative;
  cursor: default;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: default;
}

.spin {
  animation: fa-spin 2s linear infinite;
}

.icon {
  display: inline-grid;
  align-items: center;
  position: relative;
}

.wrapper {
  display: inline-flex;
  position: relative;

  &:hover {
    svg[fill],
    g[fill],
    path[fill],
    circle[fill] {
      fill: #a9a4a4;
    }
  }
}

.clickable {
  inset: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > * {
    pointer-events: none;
  }
}

.noClickable {
  pointer-events: none;
}
