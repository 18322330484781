@import "/src/assets/styles/variables";

.container {
  padding: 24px 16px;
  background-color: #fff;
  // height: calc(100% - 48px);
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));
  width: calc(100% - 48px);
  position: fixed;
  display: flex;
  flex-direction: column;
  left: var(--spacing-3x);
  top: var(--spacing-4x);
  border-radius: 16px;
  z-index: 2;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  max-height: 80vh;
  // max-height: 600px;
  @media (max-width: 768px) {
    height: calc(100% - 50px);
  }
  // height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  @media (min-width: 768px) {
    overflow: auto;
    max-width: 550px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-height: 300px;
  }
}

.bigModal {
  max-height: 100%;
}

.closeContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.closeIcon {
  width: auto;
  cursor: pointer;
}

.height50 {
  // top: 20%;
  @media (max-width: 768px) {
    height: 450px;
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-family: $gt-america-condensed-bold;
  color: $black;
  text-align: center;
}

.subtitle, .subtext {
  font-size: 0.8rem;
  color: $trula-black;
  opacity: 0.6;
  margin-top: var(--spacing-x);
}

.subtext {
  margin-top: 6px;
}

.content {
  margin-top: 24px;
  overflow: auto;
  height: 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 12px;
}

.centeredContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .smallModal {
    height: 500px;
  }

  .mediumModal {
    height: 600px;
  }

  .largeModal {
    height: calc(100% - 25px);
  }
}

@media (min-width: 768px) {
  .smallModal {
    height: 400px;
  }

  .mediumModal {
    height: 500px;
  }
  .largeModal {
    height: 800px;
  }
}
