@import "../../../../../assets/styles/variables";

.selectableCardIcon {
  line-height: 12px;
  border: 2px solid transparent;
  border-radius: 50%;
  overflow: hidden;
  width: 103px;
  height: 103px;
  background-color: white;
}

.selected {
  border: 2px solid var(--color-blue-800);
}
