@import "/src/assets/styles/variables";

.buttonContainer {
  margin: 24px 0;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-3x);
  font-family: $gt-america-condensed-bold;
  align-self: center;
  text-align: center;
}

.modalTitle {
  font-size: 1.6rem;
}

.modalButtonContainer {
  bottom: 24px;
  margin-top: auto;
  position: absolute;
  left: 0;
  width: calc(100% - 24px);
  text-align: center;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  padding: 0 30%;
}

.rescheduleButtonContainer {
  margin-top: 32px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
}
