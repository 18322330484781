@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 16px;
}

.notesContainer {
  padding-top: 8px;
  list-style-type: disc;
  li {
    word-break: break-word;
  }
}

.title {
  font-size: 0.875rem;
  color: $dark-blue;
}

.noteDate {
  font-size: 0.75rem;
  font-family: $gt-america;
  color: $dark-blue;
  letter-spacing: 0.04em;
  line-height: 13px;
  border-bottom: 1px solid #dddd;
  padding-bottom: 8px;
}

.note {
  color: $trula-black;
  font-size: 1rem;
  font-family: $gt-america;
  line-height: 24px;
  margin-left: 24px;
}
