@import "/src/assets/styles/variables";

.learnMore {
  text-decoration: underline;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  line-height: 18px;
  color: $dark-blue;
  cursor: pointer;
  width: fit-content;
}

.percentage {
  // padding-left: 10px;
  font-size: 0.75rem;
  font-family: $gt-america;
  color: $trula-smoke;
  white-space: nowrap;
}

.basicInfo {
  font-size: 0.75rem;
  font-family: $gt-america;
  // font-weight: 700;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  gap: 8px;
  text-align: left;
}

.userBasicInfoContainer {
  display: flex;
  flex-direction: row;
}

.avatar {
  border-radius: 50%;
}

.avatarXl {
  width: 120px;
  height: 120px;
  border-radius: 100%;
}

.avatarLg {
  width: 96px;
  height: 96px;
  border-radius: 100%;
}

.avatarSm {
  width: 46px;
  height: 46px;
  border-radius: 100%;
}

.basicInfo {
  font-size: 0.75rem;
  font-family: $gt-america;
  // font-weight: 700;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  gap: 8px;
}

.userBasicInfoContainer {
  display: flex;
  flex-direction: row;
}

.name {
  color: $dark-teal;
  font-size: 1rem;
  font-family: $gt-america-bold;
  line-height: 20px;
  padding-right: 10px;
}

.nameXl {
  font-family: $gt-america-bold;
  color: $dark-teal;
  font-size: 1.5rem;
  line-height: 20px;
  text-align: center;
  padding: 8px;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
  padding: 12px 0;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.separator::before,
.separator::after {
  content: "";
  padding: 0 12px;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.interestsBar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: $gt-america-condensed-bold;
  font-size: 0.625rem;
  padding-bottom: 8px;
  text-align: left;
}

.interestsListing {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.plain {
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.04em;
  padding-top: 7px;
}

.marginTopMinus8 {
  margin-top: -8px;
}

.marginBottom {
  margin-bottom: 24px;
}

.bold {
  font-family: $gt-america-bold;
}
