.tableContainer {
  position: relative;
  max-height: 100%;
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 var(--spacing-x);

  thead {
    position: sticky;
    z-index: 3;
    top: 0;
  }

  th {
    text-align: left;
    padding: var(--spacing-x) var(--spacing-2x);
    background-color: var(--color-gray-200);
    color: var(--color-gray-700);
    font-weight: 700;

    &:last-child div {
      justify-content: end;
    }
  }

  th,
  td {
    user-select: text;
    font-size: 14px;
    line-height: 24px;
  }

  tr {
    td:first-of-type {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        bottom: 8px;
        left: 0;
        width: 2px;
      }
    }

    &[data-ribbon-color="green"] {
      td:first-of-type {
        &:before {
          background-color: rgba(13, 177, 39, 1);
        }
      }
    }

    &[data-ribbon-color="red"] {
      td:first-of-type {
        &:before {
          background-color: var(--color-red-500);
        }
      }
    }

    &[data-ribbon-color="yellow"] {
      td:first-of-type {
        &:before {
          background-color: rgba(251, 183, 59, 1);
        }
      }
    }

    &[data-ribbon-color="grey"] {
      td:first-of-type {
        &:before {
          background-color: rgba(113, 128, 150, 1)
        }
      }
    }

    &[data-row-is-clickable] {
      cursor: pointer;
      transition: none;
    }

    &[data-highlighted="true"] {

      & > td {
        background: rgba(255, 252, 252, 1);
        border: 1px solid var(--color-red-300);

        &:first-of-type:before {
          display: none;
        }

        &:not(:first-child) {
          border-left: none;
        }

        &:not(:last-child) {
          border-right: none;
        }
      }
    }
  }

  .tbody {
    position: relative;

    tr:not(:last-child) {
      margin-bottom: var(--spacing-x);
    }

    td {
      background-color: var(--color-white);
      padding: 12px;
    }

    td:first-child {
      border-top-left-radius: var(--spacing-x);
      border-bottom-left-radius: var(--spacing-x);
    }

    td:last-child {
      border-top-right-radius: var(--spacing-x);
      border-bottom-right-radius: var(--spacing-x);
    }
  }
}
