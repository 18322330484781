@import "/src/assets/styles/variables";

.container {
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  background-color: $white;
  justify-content: space-between;
}

.subcontainer {
  display: flex;
  flex-direction: column;
}

.upper {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.text {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 4px;
  justify-content: center;
}

.name {
  font-family: $gt-america-bold;
  font-size: 1rem;
  color: $dark-teal;
}

.interests {
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.avatar {
  border-radius: 100%;
  height: 48px;
  width: 48px;
}

.lower {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.selected {
  outline: 3px solid $teal;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: $gt-america-bold;
  font-size: 0.875rem;
  text-align: center;
  line-height: 23px;
  color: $trula-black;
  opacity: 0.6;
  padding: 12px 0;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.separator::before,
.separator::after {
  content: "";
  padding: 0 12px;
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(33, 33, 33, 0.16);
}

.nextSessionLabel {
  font-family: $gt-america-condensed-bold;
  font-size: 0.7rem;
}

.nextSessionValue {
  color: $dark-blue;
  font-family: $gt-america-bold;
  font-size: 0.7rem;
  text-transform: uppercase;
}
